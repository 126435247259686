<div class="max-h-fit px-10 lg:px-20 pt-10">
    <prism-title title="Districts"></prism-title>
    @if (!isDistrictAdmin) {
      <div class="grid grid-cols-2 py-2">
        <div class="w-full lg:w-3/4">
            <prism-input 
              inputType="text" 
              [control]="searchForm.get('search')"
              [required]="false"
              inputType="text"
              placeHolder="Search districts"
              [hasSearch]="true"
              (search)="getDistricts()">
            </prism-input>
        </div>
        <div class="pb-4 flex justify-end">
            <prism-btn 
              btnClass="bg-white text-dark-purple font-bold border-2  p-0 m-0 rounded-xl border-dark-purple"
              (click)="addDistrict()">
                <i class="uil uil-plus text-dark-purple font-bold pr-1"></i>Add District
            </prism-btn>
        </div>
      </div>
    }
    <div>
        <prism-table [columns]="columns" 
            [colHeaders]="colHeaders" 
            [tableData]="data" 
            [isDelete]="true" 
            [isEdit]="true"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [totalData]="totalData"
            (edit)="editDistrict($event)"
            (pageIndexChange)="handlePageIndexChange($event)"
            (pageSizeChange)="handlePageSizeChange($event)"
            (delete)="deleteDistrict($event)"
            (navigate)="navigate($event)"
            [hideActionBtns]="isDistrictAdmin">
        </prism-table>
    </div>
</div>
<nz-modal 
  [(nzVisible)]="isAddEditDistrict" 
  [nzTitle]="modalTitle" 
  nzOkText="Save"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleSave()" 
  [nzOkDisabled]="districtForm.pristine">
  <ng-container *nzModalContent>
    <form [formGroup]="districtForm" class="grid gap-4">
        <prism-input
          [control]="name"
          label="District Name"
          placeHolder="District Name"
          [required]="true"
          [inputType]="InputType.Text">
        </prism-input>
        <div class="col-span-1">
          <prism-select-county
            [control]="countyId"
            [required]="true"
            label="County"
            [readOnly]="isDistrictAdmin">
          </prism-select-county>
        </div>
    </form>
  </ng-container>
</nz-modal>