import { Injectable, inject } from '@angular/core';
import { DataService } from '../../../../core/services/data.service';
import { Observable } from 'rxjs';
import { IJournal, IJournalRequest, IJournalResponse, IJournalSection } from '../interface/ijournal';

@Injectable({
  providedIn: 'root'
})
export class JournalService {
  dataService = inject(DataService);

  addJournal(payload: IJournal): Observable<boolean> {
    return this.dataService.post('journal', payload);
  }

  editJournal(payload: IJournal): Observable<boolean> {
    return this.dataService.post('journal/edit', payload);
  }

  getByCourseId(courseId: number): Observable<IJournalResponse[]> {
    return this.dataService.get(`journal/course/${courseId}`)
  }

  downloadJournals(payload: IJournalRequest): Observable<any> {
    return this.dataService.postWithOptions('journal/download', payload, {
      observe: 'response',
      responseType: 'blob',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  }

  getJournalSections(courseId: number): Observable<IJournalSection[]> {
    return this.dataService.get(`lookup/journal-sections/course/${courseId}`)
  }
}
