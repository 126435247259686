import { Component, OnInit, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@core/enums/roles';
import { ICourses } from '@core/interfaces/ilookup';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { switchMap, take, tap } from 'rxjs';
import { CoursesService } from './services/courses.service';
import { ICourseAttachment } from './interface/icourse-attachment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@core/services/toast.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IUserCourses } from '@core/interfaces/iuser-courses';
import { InputType } from '@core/enums/input-type';
@Component({
  selector: 'prism-course',
  templateUrl: './course.component.html',
  styleUrl: './course.component.scss'
})
export class CourseComponent implements OnInit {
  private fb = inject(FormBuilder);
  private lookupService = inject(LookupService);
  private courseService = inject(CoursesService);
  private authService = inject(AuthService);
  private sanitizer = inject(DomSanitizer);
  private router = inject(ActivatedRoute);
  private toastService = inject(ToastService);
  private modal= inject(NzModalService);
  private route = inject(Router);
  private resourceContainer = 'courses';
  private attachmentContainer = 'course-attachments';
  courseSasToken: string = '';
  attachmentSasToken: string = '';
  course?: ICourses;
  attachments: ICourseAttachment[] = [];
  courseId = 0;
  user = this.authService.getCurrentUser();
  role = Role;
  isAdmin = this.user?.roleId === Role.SystemAdmin;
  isEdit = false;
  startCourse = false;
  blobImgUrl: SafeResourceUrl = '';
  checked = false;
  completeForm: FormGroup | any;
  userCourse?: IUserCourses;
  viewCertificate = false;
  InputType = InputType;
  btnText: string = 'Start';
  editCourseForm: FormGroup | any;
  addAttachment: boolean = false;
  courseName = 'course';
  allSectionsComplete = false;

  get isCompleted() { return this.completeForm.get('isCompleted'); }

  ngOnInit(): void {
    this.router.params.subscribe(params => {
      this.courseId= +params['courseId']; 
      this.courseName = this.courseName.concat(this.courseId.toString());
    });
    
    this.getSasTokenAndFetchCourses();
    this.getSasTokenAndFetchAttachments();
    this.initForm();
  }
  
  initForm(): void {
    this.completeForm = this.fb.group({
      isCompleted: [false, Validators.required],
      courseId: [this.courseId, Validators.required]
    });
    this.getUserCourse();
  }

  getSasTokenAndFetchAttachments(): void {
    this.authService.getStorageSasToken(this.attachmentContainer).pipe(
      take(1),
      tap((res: any) => {
        this.attachmentSasToken = res.value;
      }),
      switchMap(() => this.courseService.getAttachments(this.courseId).pipe(take(1))),
      tap((attachments: ICourseAttachment[]) => {
        this.attachments = attachments.map((attachment: ICourseAttachment) => { 
          attachment.blobUrlSas = this.generateUrlWithSasToken(attachment.blobUrl, this.attachmentSasToken);
          return attachment; // Return the modified attachment
        });
      })
    ).subscribe();
  }

  getSasTokenAndFetchCourses(): void {
    this.authService.getStorageSasToken(this.resourceContainer).pipe(
      take(1),
      tap((res: any) => {
        this.courseSasToken = res.value;
      }),
      switchMap(() => this.lookupService.getCourses().pipe(take(1))),
      tap((courses: ICourses[]) => {
        this.course = courses.find(course => course.id === this.courseId);

        if (this.course) {
          this.blobImgUrl = this.generateUrlWithSasToken(this.course?.thumbnail, this.courseSasToken);
        }
      })
    ).subscribe();
  }

  getUserCourse(): void {
    this.courseService.getUserCourse(this.courseId)
            .pipe(
              take(1),
              tap((userCourse: IUserCourses) => {
                this.userCourse = userCourse;
                this.viewCertificate = this.userCourse?.isCompleted ?? false;
                this.isCompleted.setValue(this.viewCertificate);
                this.btnText = this.userCourse?.isCompleted ? 'View' : 'Start';
              })
            ).subscribe();
  }

  generateUrlWithSasToken(blobUrl?: string, sasToken?: string) {
    if (sasToken && blobUrl) {
      const urlWithSasToken = `${blobUrl}${sasToken}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(urlWithSasToken);
    }
    return '';
  }

  toggleEdit(): void {
    this.isEdit = !this.isEdit;
    this.startCourse = false;
  }

  toggleStartCourse(): void {
    if (!this.isCompleted.value) {
      this.modal.confirm({
        nzContent: 'Please note: to receive credit for this course, it must be completed in one sitting. Please ensure you have sufficient time before you begin.',
        nzCancelText: 'Close',
        nzOkText: 'Ok',
        nzOnOk: () => {
          this.startCourse = !this.startCourse;
          this.isEdit = false;
          this.completeForm.reset();
  
          if (!this.userCourse) {
            this.addUserCourse();
          }
        }
      });
    } else {
      this.startCourse = !this.startCourse;
      this.isEdit = false;
      this.completeForm.reset();
      if (!this.userCourse) {
        this.addUserCourse();
      }
    }
  }

  viewPrintCertificate(): void {
    this.courseService.downloadCertificate(this.courseId).pipe(
      take(1)
    ).subscribe(
      (blob: any) => {
        const url = window.URL.createObjectURL(blob);
        console.log('Certificate URL:', url);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Certificate.pdf';
        link.click();
        window.URL.revokeObjectURL(url);
      }
    );
  }

  addUserCourse(): void {
    this.courseService.addUserCourse(this.courseId).pipe(
      tap((res: boolean) => {
        if (res) {
          this.getUserCourse();
        }
      })
    ).subscribe();
  }

  handleAllComplete(isAllComplete: boolean): void {
    this.allSectionsComplete = isAllComplete;
  }

  submit() {
    if (!this.isCompleted.value) {
      this.toastService.error('Please agree to the terms and conditions to continue');
      return;
    }

    const iframe = document.getElementById('course-iframe') as HTMLIFrameElement;
    const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
    const progressText = iframeDocument?.querySelector('.nav-sidebar-header__progress-text')?.innerHTML;

    if (progressText !== '100% COMPLETE') {
      this.toastService.error('Please complete the course to continue');
      return;
    }

    if (!this.allSectionsComplete) {
      this.toastService.error('Please complete all Journal Entry sections to continue');
      return;
    }

    if (this.courseId > 0) {
      this.modal.confirm({
        nzTitle: `Complete Course`,
        nzContent: 'Will be redirected to Course page.',
        nzOkText: 'Yes',
        nzOnOk: () => {
          this.courseService.completeCourse(this.courseId).pipe(
            tap((res: boolean) => {
              if (res) {
                this.toastService.success('Course completed.');
                this.toggleStartCourse();
                this.getUserCourse();
              }
            })
          ).subscribe();
        },
        nzCancelText: 'Close'
      });
    }
  }
  
  updateAttachments(): void {
    this.getSasTokenAndFetchAttachments();
  }

  updateCourse(): void {
    this.isEdit = false;
    this.getSasTokenAndFetchCourses();
    this.getSasTokenAndFetchAttachments();
  }
}
