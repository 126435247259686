<div class="page-title pl-5">Add User</div>
<form class="grid grid-cols-2 gap-x-4 m-5" [formGroup]="userForm">

  <div class="col-span-2 lg:col-span-1 items-center w-[100%] pb-4">
    <prism-input
        [control]="userForm.get('firstName')"
        placeHolder="Enter first name"
        label="First Name"
        [required]="true"
        inputType="text"
        [maxLength]="50"
        >
    </prism-input>
  </div>

  <div class="col-span-2 lg:col-span-1 items-center w-[100%] pb-4">
    <prism-input
        [control]="userForm.get('lastName')"
        placeHolder="Enter last name"
        label="Last Name"
        [required]="true"
        inputType="text"
        [maxLength]="50"
        >
    </prism-input>
  </div>

  <div class="col-span-2 lg:col-span-1 items-center w-[100%] pb-4">
    <prism-select-organization
          [control]="userForm.get('districtId')"
          label="Organization"
          placeHolder="Select Organization"
          [required]="roleId?.value !== roles.SystemAdmin"
          [readOnly]="isDistrictAdmin">
    </prism-select-organization>
  </div>

  <div class="col-span-2 lg:col-span-1 items-center w-[100%] pb-4">
    <prism-input
        [control]="userForm.get('emailName')"
        placeHolder="Enter email"
        label="Email"
        [required]="true"
        inputType="email"
        >
    </prism-input>
  </div>

  <div class="col-span-2 lg:col-span-1 items-center w-[100%] pb-4">
    <prism-select-grade
          [control]="userForm.get('gradeId')"
          label="Grade Level"
          placeHolder="Select Grade Level"
          [required]="true">
    </prism-select-grade>
  </div>

  <div class="col-span-2 lg:col-span-1 items-center w-[100%] pb-4">
    <prism-select-role
          [control]="userForm.get('roleId')"
          label="Roles"
          placeHolder="Select Roles"
          [required]="true">
    </prism-select-role>
  </div>

  <div class="col-span-2 items-end w-[100%] pb-4">
    <prism-btn 
          btnClass="bg-white text-dark-purple font-bold border-2  p-0 m-0 rounded-xl border-dark-purple w-full lg:w-1/4"
          (click)="saveUser()"
      >Save
    </prism-btn>
  </div>
</form>
