<div class="px-20">
  <div class="md:flex md:flex-row justify-between mt-4">
    <prism-title [title]="formType + ' ' + resourceType"></prism-title>
    @if (formType == 'Edit') {
      <prism-btn  
        btnClass="mb-4 md:mb-0 md:mt-2 bg-white text-dark-purple font-bold border-2 p-0 m-0 rounded-xl border-dark-purple"
        (click)="deleteResource()">
        <i class="uil uil-trash-alt text-lg"></i>
        <span class="text-lg"> Delete {{ resourceType }}</span>
      </prism-btn>
    }
  </div>
  <form [formGroup]="resourceForm"
    class="flex flex-col gap-4 mb-4"
    (ngSubmit)="submitResource()">
    <prism-input
      [control]="resourceForm.get('title')"
      label="Title"
      [maxLength]="200"
      placeHolder="Enter title"
      [required]="true">
    </prism-input>
    @if (isExternal) {
      <prism-input
        [control]="resourceForm.get('description')"
        label="Description"
        placeHolder="Enter description"
        [inputType]="InputType.Textarea"
        [required]="true">
      </prism-input>
    }
    @else { 
       <prism-input
        [control]="resourceForm.get('description')"
        label="Description"
        placeHolder="Enter Description"
        [inputType]="InputType.RichTextEditor"
        [required]="true"
        (textChange)="onTextChange($event)">
      </prism-input> 
     }
    <div class="col-span-2">
      <prism-input
        [control]="resourceForm.get('imageFileName')"
        label="Thumbnail/Cover Photo"
        placeHolder="Select Photo"
        [inputType]="InputType.File"
        [required]="true"
        (fileChange)="fileChange($event)">
      </prism-input>
    </div>
    @if (isExternal) {
      <prism-input
        [control]="resourceForm.get('buttonLabel')"
        label="Button Label"
        placeHolder="Enter label"
        [required]="true">
      </prism-input>
      <prism-input
        [control]="resourceForm.get('buttonLink')"
        label="Button Link"
        placeHolder="Enter link"
        [required]="true">
      </prism-input>
    }
    @else {
      <prism-input
      [control]="resourceForm.get('videoLink')"
      label="Link"
      placeHolder="Enter link"
      [required]="true">
    </prism-input>
    }
    <div class="flex gap-2">
      <prism-btn btnClass="btn-white" (click)="handleCancel()">
        Cancel
      </prism-btn>
      <prism-btn btnClass="btn-purple" [disabled]="!resourceForm.valid">
        {{ formBtnLabel }}
      </prism-btn>
    </div>
  </form>
</div>
