// course-view.component.ts
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, inject, Input, Output, SecurityContext, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { JournalService } from '../services/journal.service';
import { IJournal, IJournalResponse, IJournalSection } from '../interface/ijournal';
import { InputType } from '@core/enums/input-type';
import { ToastService } from '@core/services/toast.service';

@Component({
 selector: 'prism-course-view',
 templateUrl: './course-view.component.html',
 styleUrl: './course-view.component.scss'
})
export class CourseViewComponent {
 private sanitizer = inject(DomSanitizer);
 private http = inject(HttpClient);
 private journalService = inject(JournalService);
 private fb = inject(FormBuilder);
 private toastService = inject(ToastService);
 @Input() courseName?: string = 'course1';
 @Input() courseId: number = 1;
 @Output() allSectionsComplete = new EventEmitter<boolean>();
 isVisible = false;
 blobUrl?: SafeResourceUrl;
 @ViewChild('course-iframe') iframeRef?: ElementRef<HTMLIFrameElement>;
 journalForm!: FormGroup;
 sections: IJournalSection[] = [];
 journalEntries: IJournalResponse[] = [];
 activePanel: number | null = null;
 InputType = InputType;
 editingEntryId: number | null = null;

 ngOnInit(): void {
   this.blobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`assets/courses/course1/index.html`);
   this.journalForm = this.fb.group({
     entry: [null, [Validators.required]]
   });

   this.loadData();
 }

 loadData(): void {
   this.journalService.getJournalSections(this.courseId).subscribe((sections) => {
     this.sections = sections.map(section => ({
       ...section,
       showEntryForm: false
     }));
   });

   this.journalService.getByCourseId(this.courseId).subscribe((entries) => {
     this.journalEntries = entries;
   });
 }

 calculateProgress(): { completed: number, total: number } {
   const total = this.sections.length;
   const completed = this.sections.filter(section => 
     this.journalEntries.some(entry => entry.journalSectionId === section.id)
   ).length;

   // Emit event when all sections are complete
   const isAllComplete = completed === total;
   this.allSectionsComplete.emit(isAllComplete);
   
   return { completed, total };
 }

 isSectionComplete(sectionId: number): boolean {
   return this.journalEntries.some(entry => entry.journalSectionId === sectionId);
 }

 showModal(): void {
   this.isVisible = true;
 }

 handleCancel(): void {
   this.isVisible = false;
 }

 editEntry(entry: IJournalResponse): void {
  this.editingEntryId = entry.id;
  this.sections = this.sections.map(section => ({
    ...section,
    showEntryForm: section.id === entry.journalSectionId
  }));
  this.activePanel = entry.journalSectionId;
  this.journalForm.patchValue({
    entry: entry.entry
  });
}

 showEntryForm(sectionId: number): void {
   this.sections = this.sections.map(section => ({
     ...section,
     showEntryForm: section.id === sectionId
   }));
   this.activePanel = sectionId;
   this.journalForm.reset();
 }

 cancelEntry(sectionId: number): void {
  this.sections = this.sections.map(section => ({ 
    ...section,
    showEntryForm: false
  }));
  this.editingEntryId = null;
  this.journalForm.reset();
}

 submitJournalEntry(sectionId: number): void {
  const entryText = this.journalForm.get('entry')?.value || '';
  
  if (entryText.length < 50) {
    this.toastService.error('Journal Entry does not meet the minimum characters.');
    return;
  }
  if (this.journalForm.valid) {
    const payload: IJournal = {
      courseId: this.courseId,
      entry: this.journalForm.value.entry,
      journalSectionId: sectionId
    };

    this.journalService.addJournal(payload).subscribe(() => {
      this.loadData();
      this.editingEntryId = null; 
      this.sections = this.sections.map(section => ({
        ...section,
        showEntryForm: false
      }));
      this.journalForm.reset();
    });
  }
 }

 updateJournalEntry(sectionId: number): void {
  if (this.journalForm.valid && this.editingEntryId) {
    const payload: IJournal & { id: number } = {
      id: this.editingEntryId,
      courseId: this.courseId,
      entry: this.journalForm.value.entry,
      journalSectionId: sectionId
    };

    this.journalService.editJournal(payload).subscribe(() => {
      this.loadData();
      this.editingEntryId = null;
      this.journalForm.reset();
    });
  }
}
}