<div class="h-screen overflow-y-clip">
  <prism-btn class="flex justify-end py-1 px-4" (click)="showModal()"
    >Journal Entry</prism-btn
  >
  <iframe
    id="course-iframe"
    [src]="blobUrl"
    width="100%"
    height="100%"
  ></iframe>

  <nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="courseName"
    (nzOnCancel)="handleCancel()"
    [nzFooter]="null"
    [nzWidth]="720"
  >
    <ng-container *nzModalContent>
      <div class="px-4">
        <div class="flex justify-between items-center mb-6">
          <h1 class="text-gray-700">Course Sections</h1>
          <div class="flex items-end gap-2">
            <span class="text-sm text-gray-600">
              {{ calculateProgress().completed }} /
              {{ calculateProgress().total }} Complete
            </span>
          </div>
        </div>

        <nz-collapse>
          <ng-container *ngFor="let section of sections">
            <nz-collapse-panel
              [nzHeader]="panelHeader"
              [nzExpandedIcon]=""
              [nzActive]="activePanel === section.id"
              class="mb-4"
            >
              <ng-template #panelHeader>
                <div class="flex items-center justify-between w-full py-2">
                  <div class="flex items-center gap-4">
                    <span
                      [class.text-green-500]="isSectionComplete(section.id)"
                      [class.text-gray-300]="!isSectionComplete(section.id)"
                    >
                      <i nz-icon nzType="check-circle" nzTheme="fill"></i>
                    </span>
                    <div>
                      <h3 class="font-medium text-gray-800">
                        {{ section.sectionName }}
                      </h3>
                      <div
                        class="flex items-center gap-2 text-sm text-gray-500"
                      ></div>
                    </div>
                  </div>
                  <div class="flex items-center gap-3">
                    <span
                      class="text-sm px-3 py-1 rounded-full"
                      [style]="
                        isSectionComplete(section.id)
                          ? { color: '#16a34a', 'background-color': '#f0fdf4' }
                          : { color: '#4b5563', 'background-color': '#f9fafb' }
                      "
                    >
                      {{
                        isSectionComplete(section.id)
                          ? "Completed"
                          : "Incomplete"
                      }}
                    </span>
                  </div>
                </div>
              </ng-template>

              <div class="space-y-4 mt-4">
                <div
                  *ngFor="let entry of journalEntries"
                  class="bg-gray-50 rounded-lg"
                >
                  <div
                    *ngIf="entry.journalSectionId === section.id"
                    class="p-4"
                  >
                    <div class="flex justify-between items-start mb-2">
                      <span class="text-sm font-semibold text-gray-500">{{
                        entry.dateSubmitted | date : "longDate"
                      }}</span>
                      <prism-btn
                        variant="text"
                        (click)="editEntry(entry); $event.stopPropagation()"
                      >
                      <i class="uil uil-edit"></i>
                      </prism-btn>
                    </div>
                    <p class="text-gray-600">{{ entry.entry }}</p>
                  </div>
                </div>

                <form
                  *ngIf="section.showEntryForm"
                  [formGroup]="journalForm"
                  (ngSubmit)="
                    editingEntryId
                      ? updateJournalEntry(section.id)
                      : submitJournalEntry(section.id)
                  "
                  class="space-y-4"
                >
                  <nz-form-item>
                    <prism-input
                      [control]="journalForm.get('entry')"
                      [label]="editingEntryId ? 'Edit Journal Entry' : 'Journal Entry'"
                      placeHolder="Enter journal entry"
                      [inputType]="InputType.Textarea"
                      [maxLength]="section.maxEntryLimit"
                    >
                    </prism-input>
                  </nz-form-item>
                  <div class="flex justify-end gap-2">
                    <prism-btn
                      variant="text"
                      (click)="
                        cancelEntry(section.id); $event.stopPropagation()
                      "
                      >Cancel</prism-btn
                    >
                    <prism-btn variant="primary" [disabled]="!journalForm.valid"
                      >{{ editingEntryId ? 'Save Edit' : 'Save Entry' }}</prism-btn
                    >
                  </div>
                </form>

                <prism-btn
                  *ngIf="!section.showEntryForm"
                  variant="outline"
                  class="w-full"
                  (click)="showEntryForm(section.id); $event.stopPropagation()"
                >
                  <i nz-icon nzType="plus"></i>
                  Add Journal Entry
                </prism-btn>
              </div>
            </nz-collapse-panel>
          </ng-container>
        </nz-collapse>
      </div>
    </ng-container>
  </nz-modal>
</div>