<div class="mt-4 px-20">
  <prism-title title="Account Settings"></prism-title>
  <form
    [formGroup]="settingsForm"
    class="grid lg:grid-cols-2 gap-x-6 gap-y-4 mb-4"
    (submit)="saveChanges()"
  >
    <div class="col-span-2 lg:col-span-1">
      <prism-input
        [control]="settingsForm.get('firstName')"
        label="First Name"
        [required]="true"
        [maxLength]="50"
      >
      </prism-input>
    </div>

    <div class="col-span-2 lg:col-span-1">
      <prism-input
        [control]="settingsForm.get('lastName')"
        label="Last Name"
        [required]="true"
        [maxLength]="50"
      >
      </prism-input>
    </div>

    <div class="col-span-2 lg:col-span-1">
      <prism-select-organization
        [control]="settingsForm.get('districtId')"
        label="Organization"
        placeHolder="Select Organization"
        [required]="true"
        [readOnly]="!isSuperAdmin"
      >
      </prism-select-organization>
    </div>

    <div class="col-span-2 lg:col-span-1">
      <prism-input
        [control]="settingsForm.get('emailName')"
        label="Email Address"
        inputType="email"
        [readOnly]="!isEditable"
        [required]="true"
      >
      </prism-input>
    </div>

    <div class="col-span-2 lg:col-span-1">
      <prism-select-grade
        [control]="settingsForm.get('gradeId')"
        label="Grade Level"
        placeHolder="Select Grade Level"
        [required]="true"
      >
      </prism-select-grade>
    </div>

    <div class="col-span-2 flex justify-start mt-4">
      <prism-btn
        btnClass="btn-purple"
        [disabled]="settingsForm.pristine || !settingsForm.valid"
      >
        Submit
      </prism-btn>
    </div>
  </form>
</div>
