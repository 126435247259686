import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, inject } from '@angular/core';
import { Action } from './enums/action';
import { IAction } from './interface/iaction';
import { AuthService } from '@core/services/auth.service';
import { Role } from '@core/enums/roles';

@Component({
  selector: 'prism-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  encapsulation: ViewEncapsulation.None 
})
export class TableComponent implements OnInit {
  private authService = inject(AuthService);
  @Output() pageIndexChange = new EventEmitter();
  @Output() pageSizeChange = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() navigate = new EventEmitter();
  @Input() columns: string[] = [];
  @Input() colHeaders: any;
  @Input() tableData: any[] = []
  @Input() isDelete = false;
  @Input() isEdit = false;
  @Input() pageSize = 10;
  @Input() pageIndex = 1;
  @Input() totalData = 0;
  @Input() hideActionBtns: boolean = false;
  actions: IAction[] = [];
  actionType = Action;
  currentUser = this.authService.getCurrentUser();
  isDistrictAdmin = this.currentUser.roleId === Role.DistrictAdmin;
  ngOnInit(): void {
    this.actions = [
      { label: 'Edit', 
        isActive: this.isEdit, 
        icon: 'uil uil-edit text-dark-purple', 
        handler: this.action.bind(this), 
        type: this.actionType.edit },
      { label: 'Delete', 
        isActive: this.isEdit, 
        icon: 'uil uil-times-circle text-red', 
        handler: this.action.bind(this), 
        type: this.actionType.delete },
    ];
    if (this.hideActionBtns) {
      this.actions = this.actions.filter(action => 
        action.type !== this.actionType.delete && action.type !== this.actionType.edit
      );       
    }
    if (this.colHeaders && this.colHeaders.hasOwnProperty('schoolCount')) {
      this.actions.unshift({
        label: 'Schools',
        isActive: this.isEdit,
        icon: 'uil uil-clipboard-alt text-dark-purple',
        handler: this.action.bind(this),
        type: this.actionType.navigate
      });
    }
  }

  action(item: any, actionType: string) {
    switch (actionType) {
      case Action.edit:
        this.edit.emit(item);
        break;
      case Action.delete:
        this.delete.emit(item);
        break;
      case Action.add:
        this.add.emit(item);
        break;
      case Action.navigate:
        this.navigate.emit(item);
        break;
      default:
        break;
    }
  }


  handlePageIndexChange(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.pageIndexChange.emit(pageIndex);
  }

  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.pageSizeChange.emit(pageSize);
  }
}
