import { Component, OnInit, inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DistrictHeaders, DistrictProps } from "../../enums/district-headers";
import { DistrictManagementService } from "../../services/district-management.service";
import { InputType } from '@core/enums/input-type';
import { AuthService } from "@core/services/auth.service";
import { Role } from "@core/enums/roles";
import { Observable, take, tap } from "rxjs";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { ToastService } from "@core/services/toast.service";
import { IDistrict, ISearchDistrictRequest, ISearchDistrictResponse } from "../../interfaces/isearchDistrict";

@Component({
  selector: 'prism-district-management',
  templateUrl: './district-management.component.html',
  styleUrl: './district-management.component.scss'
})
export class DistrictManagementComponent implements OnInit{
  private fb = inject(FormBuilder);
  private districtService= inject(DistrictManagementService);
  private authService = inject(AuthService);
  private modal = inject(NzModalService);
  private router = inject(Router);
  private toastService = inject(ToastService);
  searchForm!: FormGroup;
  districtForm!: FormGroup;
  columns = ['name', 'schoolCount', 'countyName'];
  data: any[] = [];
  colHeaders = DistrictHeaders;
  DistrictProps = DistrictProps;
  roles = Role;
  pageIndex = 1;
  pageSize = 10;
  totalData = 0;
  isAddEditDistrict: boolean = false;
  currentUser = this.authService.getCurrentUser();
  isDistrictAdmin = this.currentUser.roleId === Role.DistrictAdmin;

  InputType = InputType;
  modalTitle: string = 'Add District';
  get search() { return this.searchForm.get('search'); }
  get name() { return this.districtForm.get('name'); }
  get countyId() { return this.districtForm.get('countyId'); }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      search: [null],
      districtId: [this.isDistrictAdmin ? this.currentUser?.districtId : null]
    }); 
    this.districtForm = this.fb.group({
      id: [0],
      name: [null, [Validators.required, Validators.maxLength(150)]],
      countyId: [null, [Validators.required]]
    }); 
    this.getDistricts();  
  }

  getDistricts() {
    let payload: ISearchDistrictRequest = {
      ...this.searchForm.value,
      pageIndex: this.pageIndex,
      itemsPerPage: this.pageSize
    };
    this.districtService.searchDistricts(payload).subscribe((response: ISearchDistrictResponse) => {
      this.data = response.items;
      this.pageIndex = response.pageIndex;
      this.totalData = response.totalData;
    });
  }

  addDistrict() {
    this.isAddEditDistrict = true;
    this.districtForm.patchValue({
      id: 0,
      name: null,
      countyId: null
    });
  }

  editDistrict(district: IDistrict) {
    this.modalTitle = 'Edit District';
    this.isAddEditDistrict = true;
    this.districtForm.patchValue({
      ...district
    });
  }

  deleteDistrict(district: IDistrict) {
    this.modal.confirm({
      nzTitle: `Delete District: ${district.name}`,
      nzContent: 'Are you sure you want to delete this district?',
      nzOkText: 'Yes',
      nzIconType: "exclamation-circle",
      nzOnOk: () => {
        this.districtService.deleteDistrict(district.id).pipe(
          tap((res: boolean) => {
            if (res) {
              this.toastService.success('District deleted successfully');
              this.getDistricts();
            }
          })
        ).subscribe();
      },
      nzCancelText: 'No',
    });
  }

  handlePageIndexChange(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.getDistricts();
  }

    
  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.getDistricts();
  }

  handleCancel(): void {
    this.isAddEditDistrict = false;
  }

  handleSave(): void {
    if (this.districtForm.invalid) {
      this.toastService.error('Please fill out all required fields.');
      return;
    }
    if (this.districtForm.value.id != 0) {
      this.processDistrict(this.districtService.updateDistrict(this.districtForm.value, 'District updated successfully'));
    } else {
      this.processDistrict(this.districtService.addDistrict(this.districtForm.value, 'District added successfully'));
    }
  }

  processDistrict(districtObservable: Observable<boolean>): void {
    districtObservable.pipe(take(1)).subscribe((success: boolean) => {
      if (success) {
        this.isAddEditDistrict = false;
        this.getDistricts();
      }
    });
  }

  navigate(item: IDistrict) {
    this.router.navigate(['/school-management', item.id]);
  }
  
}