import { Component, ElementRef, OnInit, inject } from '@angular/core';
import { UserManagementService } from '../../services/user-management.service';
import { UserHeaders, UserProps } from '../../enums/user-headers';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ISearchUsers, ISearchUsersResponse } from '../../interfaces/isearchUsers';
import { IDistricts, IRoles } from '@core/interfaces/ilookup';
import { LookupService } from '@core/services/lookup.service';
import { distinctUntilChanged, take, tap } from 'rxjs';
import { Role } from '@core/enums/roles';
import { IUser } from '@core/interfaces/iuser';
import { ToastService } from '@core/services/toast.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'prism-user-management',
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss'
})
  export class UserManagementComponent implements OnInit{
    private userManagementService = inject(UserManagementService);
    private authService = inject(AuthService);
    private lookupService= inject(LookupService);
    private toastService= inject(ToastService);
    private modal= inject(NzModalService);
    private fb = inject(FormBuilder);
    columns = ['fullName', 'emailName', 'userTypeName', 'organizationName'];
    data: any[] = [];
    colHeaders = UserHeaders;
    UserProps = UserProps;
    isVisible = false;
    userForm!: FormGroup;
    searchForm!: FormGroup;
    roles = Role;
    roleOptions: IRoles[] = [];
    organizationOptions: IDistricts[] = [];
    modalTitle = 'Add User';
    pageIndex = 1;
    pageSize = 10;
    totalData = 0;
    currentUser = this.authService.getCurrentUser();
    isDistrictAdmin = this.currentUser.roleId === Role.DistrictAdmin;
    get firstName() {
      return this.userForm.get('firstName');
    }
    get lastName() {
      return this.userForm.get('lastName');
    }
    get gradeId() {
      return this.userForm.get('gradeId');
    }
    get emailName() {
      return this.userForm.get('emailName');
    }
    get roleId() {
      return this.userForm.get('roleId');
    }
    get districtId() {
      return this.userForm.get('districtId');
    }
    get search() {
      return this.searchForm.get('search');
    }
    get organizationId() {
      return this.searchForm.get('organizationId');
    }

  constructor(private eRef: ElementRef) {}

  ngOnInit(): void {
    this.initializeForms();
    this.searchUsers();
    this.getOrganizations();
    this.listenUserType();
    this.listenOrganization();
  }

  initializeForms(): void {
    this.userForm = this.fb.group({
      id: [0],
      firstName: [null, [Validators.required, Validators.maxLength(50)]],
      lastName: [null, [Validators.required, Validators.maxLength(50)]],
      gradeId: [null, Validators.required],
      emailName: [null, Validators.required],
      roleId: [null, Validators.required],
      districtId: [null, Validators.required],
    });

    this.searchForm = this.fb.group({
      search: [''],
      organizationId: [this.currentUser.roleId == Role.DistrictAdmin ? this.currentUser.districtId : null]
    });
  }

  requiredIfRoleSelectedValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const roleId = this.roleId?.value;

        if (roleId !== Role.SystemAdmin) {
            return { 'required': true };
        }

        return null;
    };
  }

  getOrganizations(): void {
    this.lookupService.getOrganizations().pipe(
      take(1),
      tap((res: IDistricts[]) => this.organizationOptions = res)
    ).subscribe();
  }

  searchUsers() {
    let payload: ISearchUsers = {
      ...this.searchForm.value,
      pageIndex: this.pageIndex,
      itemsPerPage: this.pageSize
    };

    this.userManagementService.getUsers(payload).pipe(
      take(1)
    ).subscribe((res: ISearchUsersResponse) => {
      this.data = res.items;
      this.pageIndex = res.pageIndex;
      this.totalData = res.totalData;
    })
  }

  editUser(user: IUser) {
    this.modalTitle = 'Edit User';
    this.userForm.patchValue({
      ...user
    });
    this.isVisible = true;
  }

  deleteUser(user: IUser) {
    this.modal.confirm({
      nzTitle: `Delete User: ${user.fullName}`,
      nzContent: 'Are you sure you want to delete this user?',
      nzOkText: 'Yes',
      nzIconType: "exclamation-circle",
      nzOnOk: () => {
        this.userManagementService.deleteUser(user.id).pipe(
          tap((res: boolean) => {
            if (res) {
              this.toastService.success('User deleted successfully');
              this.searchUsers();
            }
          })
        ).subscribe();
      },
      nzCancelText: 'No',
    });
  }

  listenUserType(): void {
    this.roleId?.valueChanges.subscribe((roleId: number) => {
      if (roleId !== Role.SystemAdmin) {
        this.districtId?.setValidators([Validators.required]);
      } else {
        this.districtId?.clearValidators();
      }
      this.districtId?.updateValueAndValidity();
    });
  }


  listenOrganization(): void {
    this.organizationId?.valueChanges.pipe(
      distinctUntilChanged(),
      tap((value: number) => {
        this.search?.setValue(null);
        this.organizationId?.setValue(value);
        this.searchUsers();
      })
    ).subscribe();

    this.districtId?.valueChanges.subscribe(() => {
      const districtIdControl = this.districtId;
      if (districtIdControl && districtIdControl.hasError('required')) {
          if (districtIdControl.value !== null && districtIdControl.value !== '') {
              districtIdControl.setErrors(null);
          }
      }
    });
  }

  handlePageIndexChange(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.searchUsers();
  }

  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.searchUsers();
  }

  handleOk(): void {
    if (this.userForm.invalid) {
      this.toastService.error('Please fill out all required fields');
      return;
    }
    const payload: IUser = {
      ...this.userForm.value
    }

    if(payload.id > 0) {
      this.userManagementService.editUser(payload).pipe(
        take(1),
        tap((res: boolean) => {
          if (res) {
            if (payload.id === this.currentUser?.userId) {
              this.toastService.success('Updated personal account successfully. Please login again.');
              this.authService.logout();
              return;
            }
            this.searchUsers();
            this.toastService.success('User updated successfully');
          }
          this.isVisible = false;
        })
      ).subscribe();
    }
  }

  handleCancel(): void {
    this.isVisible = false;
  }
}
